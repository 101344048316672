import './index.sass'
import '../../main.css'
import './burger-styles.sass'
import {observer} from "mobx-react";
import StoreContext from "../../store/RootStore";
import ChooseWalletModal from "../chooseWalletModal";
import {useEffect, useState} from "react";
import LoginHandler from "../../functions/login";
import { Sidenav, Nav, Dropdown } from 'rsuite';
import {UALContext} from "ual-reactjs-renderer";
import {useContext} from "react";

function Header() {
    const {AccountStore} = StoreContext();
    const [modalOpened, setModalOpened] = useState(false);
    const [logOutOpened, setLogOutOpened] = useState(false);
    const loginHandler = new LoginHandler();
    const ual = useContext(UALContext);

    useEffect(() => {
        if(ual.activeUser)
        loginHandler.login(ual.activeUser);
    })

    const login = () => {
        if(ual.activeUser)
        loginHandler.login(ual.activeUser);
        else
        {
        ual.logout();
        ual.showModal();
        }
    }

    return (
        <div >
            <Sidenav defaultOpenKeys={['3', '4']} >
              <Sidenav.Body>
<div className="menu">
              <div id="avatar">
            <a href="./avatars">
                    <img
                     src={`https://dov-gw.mypinata.cloud/ipfs/${AccountStore.getAvatarIndex().ipfs}`} 
                    alt=""
                    className="imgavatar"
                     onClick={(e)=> {
                        
                    }}/></a>
              </div>
    <div id="account_block">
        <span id="account_name">{AccountStore.accountAddress}</span>
                 {AccountStore.accountAddress ?
                <div className="header-wallet" onClick={()=>setLogOutOpened(true)}>
                    <img className="header-wallet-icon"/>
                    <div className={`header-wallet-logout-wrapper ${logOutOpened ? "header-wallet-logout-wrapper-active" : ""}`}>
                        <div className="header-wallet-logout-cross" onClick={(e)=>{
                            e.stopPropagation();
                            setLogOutOpened(false);
                        }}>
                            <div className="header-wallet-logout-cross-line"/>
                            <div className="header-wallet-logout-cross-line"/>
                        </div>
                        <button className="header-wallet-logout" onClick={(e)=> {
                                    ual.logout();
                            e.stopPropagation();
                            setLogOutOpened(false);
                            AccountStore.setUserData([]);
                            AccountStore.changeAccountAddress(null);
                        }}>Log out</button>
                    </div>
                </div>
                : <button className="header-log-in" onClick={() => login()}>Log In</button>}
            <ChooseWalletModal opened={modalOpened} setOpened={setModalOpened}/>
    </div>
    <div id="menuTheater">
        <span>Theater of war</span>
        <div id="theater1" className="midMenu">
        <a href="./fight">PVE</a>
        </div>
        <div id="theater2" className="midMenu">
            <a href="">PVP</a>
        </div>
    </div>
    <div id="menuDivision" >
        <span>My Divisions</span>
        <div id="createDivision" className="midMenuWar">
            <a href="./create">Create</a>
        </div>
        <div id="fightDivision" className="midMenuWar" >
            <a href="./fight">Fight</a>
        </div>
    </div>
    <div id="menuWar">
        <span>War Buildings</span>
        <div id="buildings1" className="midMenuWar">
            <a href="./">Buildings</a>
        </div>
        <div id="buildings2" className="midMenuWar">
            <a href="./exchange">Exchange</a>
        </div>
    </div>
    <div id="menuAssets">
        <span >My assets</span>
        <div id="assets1" className="midMenuMin">
            <a href="./craft">Craft</a>
        </div>
        <div id="assets2" className="midMenuMin">
            <a href="./tools">Tools</a>
        </div>
    </div>
    <div id="menuMissions" className="btn">
        <a href="">Missions</a>
    </div>
    <div id="menuHOF" className="btn">
        <a href="./hof">Hall of Fame</a>
    </div>
</div>
              </Sidenav.Body>
            </Sidenav>
          </div>
    )
}

export default observer(Header);

//0.6498