import StoreContext from "../store/RootStore";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import AnchorLink from "anchor-link";
import * as waxjs from "@waxio/waxjs/dist";
import { NotificationManager } from "react-notifications";
import eosjsName from "eosjs-account-name";

export default class LoginHandler {
  constructor() {
    const { AccountStore } = StoreContext();
    this.AccountStore = AccountStore;
    const transport = new AnchorLinkBrowserTransport();
    this.endpoint = "https://wax.api.eosnation.io"; //"https://wax-test.blokcrafters.io";
    //this.endpoint = "https://dovx-wax-rpc.global.binfra.one";
    this.anchorLink = new AnchorLink({
      transport,
      chains: [
        {
          chainId:
            "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
          nodeUrl: this.endpoint,
        },
      ],
    });
    this.wax = new waxjs.WaxJS({
      rpcEndpoint: "https://wax.api.eosnation.io",
      tryAutoLogin: false,
    });
    this.dapp = "dovutilstake";
    this.tokenContract = "dovvaultfort";
    this.contract = "dovutilstake";
    this.Gamecontract = "dovpvecontrl";
    this.collection = "dovdivisions";
    this.aa = "https://wax-atomic-api.eosphere.io	";
    //this.aa = "https://dovx-wax-aa.global.binfra.one";
  }

  async getUserBalancetkn() {
    let balances = {
      dovx: "0.00",
      dovr: "0.00",
      dovs: "0.00",
      dovf: "0.00",
      dovh: "0.00",
      avt: 1,
    };
    try {
      const tableRows = await this.wax.rpc.get_table_rows({
        json: true,
        code: "dovutilstake",
        scope: this.AccountStore.accountAddress,
        table: "balances",
      });
      if (tableRows.rows.length === 0) {
      } else {
        for (let i = 0; i < tableRows.rows.length; i++) {
          if (tableRows.rows[i].balance.includes("DOVX")) {
            balances.dovx = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVR")) {
            balances.dovr = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVS")) {
            balances.dovs = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVF")) {
            balances.dovf = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVH")) {
            balances.dovh = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("AVT")) {
            balances.avt = parseInt(tableRows.rows[i].balance);
          }
        }
        this.AccountStore.setBlcData(balances);
        if (this.AccountStore.avatarData.length === 0) {
          await this.getAvConfig();
        }
        return balances;
      }
    } catch (e) {}
  }

  async getConfig() {
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: "dovutilstake",
      scope: "dovutilstake",
      table: "configs",
      limit: 4,
    });
    const data = body.rows;
    if (data.length !== 0) {
      let dataa = data[0];
      this.AccountStore.setConfig(dataa);
    }
  }

  async getAvConfig() {
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Gamecontract,
      scope: this.Gamecontract,
      table: "avatars",
      limit: 100,
    });
    const data = body.rows;
    if (data.length !== 0) {
      this.AccountStore.setAvatarConfig(data);
    }
  }

  async getUserBalancewax() {
    try {
      let balancez = {
        dovx: "0.00",
        dovr: "0.00",
        dovs: "0.00",
        dovf: "0.00",
        dovh: "0.00",
      };
      const tableRows = await this.wax.rpc.get_table_rows({
        json: true,
        code: this.tokenContract,
        scope: this.AccountStore.accountAddress,
        table: "accounts",
      });
      if (tableRows.rows.length === 0) {
      } else {
        for (let i = 0; i < tableRows.rows.length; i++) {
          if (tableRows.rows[i].balance.includes("DOVX")) {
            balancez.dovx = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVR")) {
            balancez.dovr = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVS")) {
            balancez.dovs = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVF")) {
            balancez.dovf = parseFloat(tableRows.rows[i].balance);
          } else if (tableRows.rows[i].balance.includes("DOVH")) {
            balancez.dovh = parseFloat(tableRows.rows[i].balance);
          }
        }
        this.AccountStore.setWBlcData(balancez);
        return balancez;
      }
    } catch (e) {}
  }

  async login(data) {
    try {
      console.log(data);
      this.AccountStore.changeAccountAddress(data.accountName);
      this.AccountStore.setUserData(data);
    } catch (e) {
      NotificationManager.error(e, "Error while logging in through Anchor");
    }
  }

  resetDivItems() {
    this.AccountStore.modifyDivItems([
      [
        {
          slot: "officer",
        },
        {
          slot: "support",
        },
        {
          slot: "tactic",
        },
        {
          slot: "godmother",
        },
        {
          slot: "combat%4",
        },
        {
          slot: "combat%5",
        },
        {
          slot: "combat%6",
        },
        {
          slot: "combat%7",
        },
        {
          slot: "combat%8",
        },
        {
          slot: "combat%9",
        },
        {
          slot: "combat%10",
        },
        {
          slot: "combat%11",
        },
        {
          slot: "combat%12",
        },
        {
          slot: "combat%13",
        },
      ],
      [
        {
          slot: "officer",
        },
        {
          slot: "support",
        },
        {
          slot: "tactic",
        },
        {
          slot: "godmother",
        },
        {
          slot: "combat%4",
        },
        {
          slot: "combat%5",
        },
        {
          slot: "combat%6",
        },
        {
          slot: "combat%7",
        },
        {
          slot: "combat%8",
        },
        {
          slot: "combat%9",
        },
        {
          slot: "combat%10",
        },
        {
          slot: "combat%11",
        },
        {
          slot: "combat%12",
        },
        {
          slot: "combat%13",
        },
      ],
      [
        {
          slot: "officer",
        },
        {
          slot: "support",
        },
        {
          slot: "tactic",
        },
        {
          slot: "godmother",
        },
        {
          slot: "combat%4",
        },
        {
          slot: "combat%5",
        },
        {
          slot: "combat%6",
        },
        {
          slot: "combat%7",
        },
        {
          slot: "combat%8",
        },
        {
          slot: "combat%9",
        },
        {
          slot: "combat%10",
        },
        {
          slot: "combat%11",
        },
        {
          slot: "combat%12",
        },
        {
          slot: "combat%13",
        },
      ],
    ]);
  }
  async getAssets() {
    this.resetDivItems();
    this.AccountStore.setLoading(false);
    try {
      const assets = [];
      const response = await fetch(
        this.aa +
          `/atomicassets/v1/assets?collection_name=${this.collection}&owner=${this.AccountStore.accountAddress}&page=1&limit=1000&order=desc&sort=asset_id`,
        {
          headers: {
            "Content-Type": "text/plain",
          },
          method: "GET",
        }
      );
      const body = await response.json();
      for (let i = 0; i < body.data.length; i++) {
        let data = body.data[i];
        assets.push({
          asset_id: data.asset_id,
          img: data.data.img,
          name: data.name,
          template_id: data.template.template_id,
          schema: data.schema.schema_name,
          fusion: data.data.fusion,
          rarity: data.data.rarity,
        });
      }
      await this.getUnitsConfig(assets);
      await this.getZonesConfig();
    } catch (e) {
      this.AccountStore.setLoading(false);
      NotificationManager.error(e.message, "An error has occurred.");
      // }
      return [];
    }
  }

  async getUnitsConfig(assets) {
    let rates = [];

    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Gamecontract,
      scope: this.Gamecontract,
      table: "udata",
      limit: 1000,
    });
    const data = body.rows;
    data.forEach((x) => {
      rates.push({
        template_id: parseInt(x.template_id),
        type: x.type,
        power: x.power,
        bonus: x.bonus,
      });
    });
    this.AccountStore.SetUConfig(rates);

    const buildings = [];
    for (let i = 0; i < assets.length; i++) {
      for (let j = 0; j < rates.length; j++) {
        let y = rates[j];
        if (parseInt(assets[i].template_id) === y.template_id) {
          assets[i].type = y.type;
          if (assets[i].type === "combat") assets[i].power = y.power;
          else assets[i].power = 0;
          assets[i].bonus = y.bonus;
          buildings.push(assets[i]);
        }
      }
    }
    this.AccountStore.setAllAssets(buildings);
    this.filterAssets(buildings);
  }

  async getZonesConfig() {
    let rates = [];
    let winData = await this.getUserWins();

    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Gamecontract,
      scope: this.Gamecontract,
      table: "zones",
      limit: 1000,
    });
    const data = body.rows;
    for (let i = 0; i < data.length; i++) {
      const x = data[i];
      let winz = false;
      if (x.prevZone > 0) {
        const y =
          data.filter((z) => z.id === x.prevZone).length > 0
            ? data.filter((z) => z.id === x.prevZone)[0]
            : null;
        if (winData.length >= 1 && y !== null) {
          var wind = winData.filter((a) => a.zone === y.id);
          winz = wind.length > 0 ? wind[0].wins >= y.nbwins : y.nbwins === 0;
        }
      } else winz = true;

      rates.push({
        id: parseInt(x.id),
        name: x.name ? x.name : x.terrain + " " + x.id,
        terrain: x.terrain,
        costs: x.costs,
        resolutions: x.resolutions.sort((a, b) => a.value - b.value),
        pre_allow: x.pre_allow,
        power: x.power.sort((a, b) => a.value - b.value),
        wmini: x.wmini,
        nbwins: x.nbwins,
        prevZone: x.prevZone,
        open: x.open,
        reachable: winz,
      });
    }
    this.AccountStore.SetselectedZone(rates[0]);
    this.AccountStore.SetZConfig(rates);
    let divs = await this.getDivisions();
  }

  async getUserWins() {
    try {
      let balancez = [];
      const tableRows = await this.wax.rpc.get_table_rows({
        json: true,
        code: this.Gamecontract,
        scope: this.AccountStore.accountAddress,
        table: "infos",
        limit: 1000,
      });
      if (tableRows.rows.length === 0) {
        return balancez;
      } else {
        for (let i = 0; i < tableRows.rows.length; i++) {
          balancez.push(tableRows.rows[i]);
        }
        this.AccountStore.SetZoneWins(balancez);
        return balancez;
      }
    } catch (e) {}
  }

  async filterAssets(assets) {
    const filteredAssets = [];

    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Gamecontract,
      scope: this.Gamecontract,
      table: "nfts",
      key_type: "i64",
      index_position: 2,
      lower_bound: eosjsName.nameToUint64(this.AccountStore.accountAddress),
      upper_bound: eosjsName.nameToUint64(this.AccountStore.accountAddress),
      limit: 1000,
    });
    const data = body.rows;

    if (data.length !== 0) {
      assets.forEach((asset) => {
        data.forEach((dataItem) => {
          if (
            dataItem.asset_id === asset.asset_id &&
            dataItem.owner === this.AccountStore.accountAddress
          ) {
            filteredAssets.push(asset);
          }
        });
      });
    }
    this.AccountStore.setStakedAssets(filteredAssets);
    const unstakedAssets = assets.filter(
      (item) => !filteredAssets.includes(item)
    );
    this.AccountStore.setLoading(false);
    this.AccountStore.setUnstakedAssets(unstakedAssets);
  }

  async getDivisions() {
    const filteredAssets = [];
    const body = await this.wax.rpc.get_table_rows({
      json: true,
      code: this.Gamecontract,
      scope: this.Gamecontract,
      table: "sdivisions",
      key_type: "i64",
      index_position: 2,
      lower_bound: eosjsName.nameToUint64(this.AccountStore.accountAddress),
      upper_bound: eosjsName.nameToUint64(this.AccountStore.accountAddress),
      limit: 1000,
    });
    const data = body.rows;
    if (data.length !== 0) {
      data.map((dataItem, index) => {
        if (dataItem.owner === this.AccountStore.accountAddress) {
          dataItem.index = index;
          let tr = parseInt(this.get_rtime(parseInt(dataItem.end_time)));
          dataItem.claimTime = parseInt(tr);
          dataItem.zone = this.AccountStore.Zconfig.filter(
            (a) => a.id === parseInt(dataItem.zoneID)
          )
            ? this.AccountStore.Zconfig.filter(
                (a) => a.id === parseInt(dataItem.zoneID)
              )[0]
            : this.AccountStore.Zconfig[0];
          dataItem = this.getPower(dataItem, -1);
          if (dataItem.zone) filteredAssets.push(dataItem);
        }
      });
    }
    this.AccountStore.setDivisions(filteredAssets);
    this.setDivItems();
  }

  handleZoneChange(zone) {
    let divisions = this.AccountStore.divisions.slice();
    if (divisions[this.AccountStore.selectedDivision]) {
      divisions[this.AccountStore.selectedDivision].zone = zone;
      divisions = this.getPower(divisions, this.AccountStore.selectedDivision);
      this.AccountStore.setDivisions(divisions);
    }
  }

  handleassetChange(index, asset, reset) {
    //console.log(index);
    let xx = this.AccountStore.divItems.slice();

    if (!reset) {
      xx[this.AccountStore.selectedDivision][index].asset = asset;
    } else {
      xx[this.AccountStore.selectedDivision][index] = {
        slot: xx[this.AccountStore.selectedDivision][index].slot,
      };
    }
    xx = this.getCPower(xx);
    this.AccountStore.modifyDivItems(xx);
  }

  resetTimer(divID) {
    for (let i = 0; i < this.AccountStore.divisions.length; i++) {
      if (this.AccountStore.divisions[i].divID === divID)
        this.AccountStore.divisions[i].end_time = -1;
    }
  }

  setDivItems() {
    let gg = this.AccountStore.divItems.slice();
    let divisions = this.AccountStore.divisions.slice();
    for (let i = 0; i < divisions.length; i++) {
      for (let j = 0; j < divisions[i].units.length; j++) {
        let asset = this.AccountStore.allAssets.filter(
          (a) => parseInt(a.asset_id) === parseInt(divisions[i].units[j].value)
        )
          ? this.AccountStore.allAssets.filter(
              (a) =>
                parseInt(a.asset_id) === parseInt(divisions[i].units[j].value)
            )[0]
          : {};
        if (asset) {
          for (let k = 0; k < gg[i].length; k++) {
            if (gg[i][k].slot === divisions[i].units[j].key) {
              gg[i][k].asset = asset;
              divisions[i].units[j].value = asset.asset_id;
            }
          }
        }
      }

      //console.log(gg);
      //console.log(divisions);
      this.AccountStore.modifyDivItems(gg);
    }
  }
  get_rtime(endtime) {
    let current = new Date().getTime() / 1000;
    let tr = endtime - current;
    if (tr < 0) tr = 0;
    let time = new Date().getTime() + tr * 1000;
    return time;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getPower(divisionCopy, index) {
    let power = 0.0;
    let bonusw = 0;
    let bonusd = 0;
    let templates = [];
    let divisionAB, division;
    if (index !== -1) {
      divisionAB = divisionCopy.slice();
      division = divisionAB[index];
    } else {
      division = divisionCopy;
    }

    for (let i = 0; i < division.units.length; i++) {
      let asset = this.AccountStore.allAssets.filter(
        (a) => parseInt(a.asset_id) === parseInt(division.units[i].value)
      )
        ? this.AccountStore.allAssets.filter(
            (a) => parseInt(a.asset_id) === parseInt(division.units[i].value)
          )[0]
        : {};
      if (asset) {
        //console.log(asset);
        let arrz = this.getBonus(
          asset,
          this.capitalizeFirstLetter(division.zone.terrain)
        );
        bonusd += arrz[0];
        bonusw += arrz[1];
        templates.push(parseInt(asset.template_id));
        if (asset.type === "combat")
          power += (asset.power / 10000.0) * (parseFloat(arrz[2]) / 10000.0);
        else power += parseFloat(asset.power / 10000.0);
      }
    }
    ////console.log(templates);
    power = power * (1.0 + parseFloat(bonusw) / 1000000);
    let win = "X";
    if (division.zone) {
      let sortedPower = division.zone.power.sort((a, b) => a.value - b.value);
      for (let i = 0; i < sortedPower.length; i++) {
        if (sortedPower[i].value / 10000 <= power) {
          win = sortedPower[i].key;
        }
      }
      let checkAll = true;
      for (let i = 0; i < division.zone.pre_allow.length; i++) {
        let tempfound = false;
        let template_id = parseInt(division.zone.pre_allow[i].value);
        if (template_id < 100 || division.zone.pre_allow[i].key === "tomint")
          tempfound = true;
        else {
          var orderVals = this.getOrder(template_id);
          var reqorder = orderVals[0];
          var reqType = orderVals[1];
          var reqFX = orderVals[2];
          ////console.log(reqorder, reqType, template_id ,reqFX);
          for (let j = 0; j < templates.length; j++) {
            var t = templates[j];
            var orderValz = this.getOrder(t);
            var assetorder = orderValz[0];
            var assetType = orderValz[1];
            var assetFX = orderValz[2];
            if (
              assetorder >= reqorder &&
              reqType === assetType &&
              reqFX === assetFX
            ) {
              tempfound = true;
            }
          }
        }
        if (!tempfound) checkAll = false;
      }
      if (!checkAll) {
        win = "X";
      }
    }
    division.win = win;
    division.Total_Power = power.toFixed(4);
    division.bonusd = bonusd;
    division.bonusw = bonusw;
    if (index !== -1) return divisionAB;
    else return division;
  }

  getCPower(divisionAC) {
    let power = 1;
    let bonusw = 1;
    let bonusd = 1;
    let templates = [];
    let divisioncx = divisionAC.slice();
    var division = divisioncx[this.AccountStore.selectedDivision];
    let divItemzC = this.AccountStore.divItems.slice();
    let divItemz = divItemzC[this.AccountStore.selectedDivision];
    for (let i = 0; i < divItemz.length; i++) {
      let item = divItemz[i].asset
        ? this.AccountStore.allAssets.filter(
            (a) => parseInt(a.asset_id) === parseInt(divItemz[i].asset.asset_id)
          )
          ? this.AccountStore.allAssets.filter(
              (a) =>
                parseInt(a.asset_id) === parseInt(divItemz[i].asset.asset_id)
            )[0]
          : {}
        : {};
      if (item !== {}) {
        let arrz = this.getBonus(
          item,
          this.capitalizeFirstLetter(division.zone.terrain)
        );
        bonusd += arrz[0];
        bonusw += arrz[1];
        templates.push(parseInt(item.template_id));
        if (item.type === "combat")
          power +=
            parseFloat(item.power / 10000.0) * (parseFloat(arrz[2]) / 10000);
        else power += parseFloat(item.power / 10000.0);
      }
    }
    let win = "X";
    if (division.zone) {
      let sortedPower = division.zone.power.sort((a, b) => a.value - b.value);
      for (let i = 0; i < sortedPower.length; i++) {
        if (sortedPower[i].value / 10000 <= power) {
          win = sortedPower[i].key;
        }
      }
      let checkAll = true;
      for (let i = 0; i < division.zone.pre_allow.length; i++) {
        let tempfound = false;
        let template_id = parseInt(division.zone.pre_allow[i].value);
        if (template_id === 0) tempfound = true;
        else {
          let req_order = 1;
          let data1 =
            this.AccountStore.Uconfig.filter(
              (a) => a.template_id === parseInt(template_id)
            )[0] || [];
          if (data1.length > 1)
            req_order = data1.bonus.filter((a) => a.key === "order")[0].value;

          for (const t in templates) {
            let asset_order = 0;
            let data2 =
              this.AccountStore.Uconfig.filter(
                (a) => a.template_id === parseInt(t)
              )[0] || [];
            if (data2.length > 1)
              asset_order = data2.bonus.filter((a) => a.key === "order")[0]
                .value;
            if (asset_order >= req_order && data1.type === data2.type) {
              tempfound = true;
            }
          }
        }
        if (!tempfound) checkAll = false;
      }
      if (!checkAll) {
        win = "X";
      }
    }
    divItemzC.win = win;
    divItemzC.Total_Power = power.toFixed(4);
    divItemzC.bonusd = bonusd;
    divItemzC.bonusw = bonusw;
    return divItemzC;
  }

  getBonus(asset, terrain) {
    let bonusd = 0;
    let bonusw = 0;
    let bonusc = 1;
    for (let i = 0; i < asset.bonus.length; i++) {
      if (asset.type === "combat") {
        if (asset.bonus[i].key === terrain) bonusc += asset.bonus[i].value;
      } else {
        if (asset.bonus[i].key === "bonusd") bonusd += asset.bonus[i].value;
        if (asset.bonus[i].key === "bonusw") bonusw += asset.bonus[i].value;
      }
    }
    return [bonusd, bonusw, bonusc];
  }

  getOrder(template_id) {
    let asset =
      this.AccountStore.Uconfig.filter(
        (a) => a.template_id === template_id
      )[0] || {};
    let order = 0;
    let fx = 0;
    if (asset.bonus) {
      for (let i = 0; i < asset.bonus.length; i++) {
        if (asset.bonus[i].key === "order") order = asset.bonus[i].value;

        if (asset.bonus[i].key === "fx") fx = asset.bonus[i].value;
      }
    }
    return [order, asset.type || "combat", fx];
  }

  async sendTransaction(actions, wallet, successMessage) {
    try {
      this.AccountStore.setLoading(true);
      const ualSession = this.AccountStore.getUserData();
      var res = await ualSession.signTransaction(
        {
          actions: actions,
        },
        {
          blocksBehind: 3,
          expireSeconds: 1500,
        }
      );
      if (res) {
        NotificationManager.success(successMessage, "Success");
      } else {
        NotificationManager.error("Transaction failed", "Error");
      }
      this.AccountStore.setLoading(false);
      return res;
    } catch (e) {
      if (e.message === "assertion failure with message: Nothing to claim") {
        NotificationManager.warning("Nothing to claim.", "Warning");
      } else {
        NotificationManager.error(e.message, "An error has occurred.");
      }
      this.AccountStore.setLoading(false);
    }
  }

  async startFight(division_id, zoneID) {
    let returnData = {
      player: this.AccountStore.accountAddress,
      division_id: division_id,
      zoneID: zoneID,
    };
    await this.sendTransaction(
      [
        {
          account: this.Gamecontract,
          name: "startfight",
          authorization: [
            {
              actor: this.AccountStore.accountAddress,
              permission: this.AccountStore.getUserData().requestPermission,
            },
          ],
          data: {
            division_id: division_id,
            player: this.AccountStore.accountAddress,
          },
        },
      ],
      this.AccountStore.getUserData()[0],
      "Transaction successfull"
    );
    setTimeout(() => {
      this.getAssets();
      this.getUserBalancetkn();
      this.getUserBalancewax();
    }, 1000);
  }

  async deleteDivision() {
    let division_id = 0;
    if (this.AccountStore.divisions[this.AccountStore.selectedDivision])
      division_id =
        this.AccountStore.divisions[this.AccountStore.selectedDivision].divID;
    else return NotificationManager.error("No division selected", "Error");
    await this.sendTransaction(
      [
        {
          account: this.Gamecontract,
          name: "deldivision",
          authorization: [
            {
              actor: this.AccountStore.accountAddress,
              permission: this.AccountStore.getUserData().requestPermission,
            },
          ],
          data: {
            asset_id: division_id,
          },
        },
      ],
      this.AccountStore.getUserData()[0],
      "Transaction successfull"
    );
    setTimeout(() => {
      this.getAssets();
    }, 1000);
  }

  async getWinsScope() {
    try {
      var allDivisions = [];
      var lb = "";
      var ub = "";
      var more = true;
      let obj = [];
      while (more) {
        const body = await this.wax.rpc.get_table_rows({
          json: true,
          code: this.Gamecontract,
          scope: this.Gamecontract,
          table: "sdivisions",
          lower_bound: lb,
          limit: 1000,
        });
        if (body.rows.length !== 0) {
          for (const data of Object.values(body.rows)) {
            var checkDuplicate = false;
            for (let i = 0; i < obj.length; i++) {
              if (obj[i] === data.owner) {
                checkDuplicate = true;
                break;
              }
            }
            if (!checkDuplicate) obj.push(data.owner);

            allDivisions.push(data);
          }
        }
        more = body.more;
        lb = body.next_key;
      }
      this.AccountStore.setAllDivisions(allDivisions);
      let dataa = await this.getBalanceBoards(obj);
      this.AccountStore.setWinsScope(dataa);
      return obj;
    } catch (e) {
      NotificationManager.error(e, e.message);
    }
  }

  async getBalanceBoards(scopes) {
    try {
      scopes.filter((item, index) => scopes.indexOf(item) === index);
      var lb = "";
      var ub = "";
      var more = true;
      let BalancesArray = [];
      let max = scopes.length - 1;
      while (max >= 0) {
        let obj = scopes[max];
        let zar = [];
        const body = await this.wax.rpc.get_table_rows({
          json: true,
          code: this.Gamecontract,
          scope: obj,
          table: "infos",
          limit: 1000,
        });
        if (body.rows.length !== 0) {
          for (const data of Object.values(body.rows)) {
            zar.push(data);
          }
        }
        var newUser = {};
        newUser.name = obj;
        newUser.balances = zar;
        max -= 1;
        BalancesArray.push(newUser);
      }
      return BalancesArray;
    } catch (e) {
      NotificationManager.error(e, e.message);
    }
  }
}
