import './index.sass'
import "../../main.css"
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import Notification from "../../components/notification";
import {NotificationManager} from "react-notifications";
import Preloader from "../../components/preloader";
import eosjsName from "eosjs-account-name";
import Pagination from "../../components/pagination";
import { get } from 'mobx';
import LoginHandler from "../../functions/login";

function ToolsPage() {
    const {AccountStore} = StoreContext();
    //var blc=AccountStore.getBlcData();
    const loginHandler = new LoginHandler();
    const [loading, setLoading] = useState(false);
    const wax = loginHandler.wax;
    const [stakedAssets, setStakedAssets] = useState([]);
    const [chosenAssets, setChosenAssets] = useState("staked");
    const [currentPage, setCurrentPage] = useState(1);
    const assetsPerPage = 4; //how many assets are shown on screen
    const [unstakedAssets, setUnstakedAssets] = useState([]);
    const [ctype, setcType]= useState("clock");
    const [popped, popUp] = useState(false);

    async function getAssets() {
        console.log(ctype);
        setLoading(true);
        try {
            const assets = [];
            const response = await fetch(loginHandler.aa+`/atomicassets/v1/assets?collection_name=${loginHandler.collection}&owner=${AccountStore.accountAddress}&schema_name=wartools&page=1&limit=1000&order=desc&sort=asset_id`, {
                headers: {
                    "Content-Type": "text/plain"
                },
                method: "GET",
            });
            const body = await response.json();
            for (let i = 0; i < body.data.length; i++) {
                let data = body.data[i];
                assets.push({
                    asset_id: data.asset_id,
                    img: data.data.img,
                    name: data.name,
                    template: data.template,
                    schema: data.schema.schema_name
                })
            }
            console.log(assets);
            await getStakingRates(assets);
        } catch (e) {
            NotificationManager.error(e.message, "An error has occurred.");
        }
    }
    
    async function getStakingRates(assets) {
        const body = await wax.rpc.get_table_rows({
            json: true,
            code: "dovutilstake",
            scope: "dovutilstake",
            table: "bdata",
            limit: 1000,
        });
        const data = body.rows;
        const rs = [];
        data.forEach(x => {
            if(!x.type.includes("building"))
            rs.push({
                template_id: x.template_id,
                type: x.type,
                token_in: x.token_in
            })
        });
        const dataa = [];
        for (let i = 0; i < assets.length; i++) {
            rs.forEach(y => {
                if (assets[i].template.template_id == y.template_id) {
                    assets[i].type = y.type;
                    assets[i].token_in = y.token_in;
                    dataa.push(assets[i]);
                }
            });
        }
        console.log(dataa);
        await filterTools(dataa);
    }

    async function filterTools(assets) {
        const filteredAssets = [];
        const finalAssets = [
            {type:"slot",varz:"clock"}, {type:"slot",varz:"booster"}, {type:"slot",varz:"effort"}
        ];
        const body = await wax.rpc.get_table_rows({
            json: true,
            code: "dovutilstake",
            scope: "dovutilstake",
            table: "mships",
            key_type: "i64",
            index_position: 2,
            lower_bound: eosjsName.nameToUint64(AccountStore.accountAddress),
            upper_bound: eosjsName.nameToUint64(AccountStore.accountAddress),
            limit: 300,
        });
        const data = body.rows;
        if (data.length !== 0) {
            assets.forEach(asset => {
                data.forEach(dataItem => {
                    if (dataItem.asset_id === asset.asset_id && dataItem.owner === AccountStore.accountAddress) {
                        asset.type=dataItem.type;
                        asset.rarity=dataItem.rarity;
                        filteredAssets.push(asset);
                        if(dataItem.type=="clock")
                        finalAssets[0]=asset;
                        if(dataItem.type=="booster")
                        finalAssets[1]=asset;
                        if(dataItem.type=="effort")
                        finalAssets[2]=asset;
                    }
                })
            })
        }
        console.log(finalAssets);
        setStakedAssets(finalAssets);
        const unstakedAssets = assets.filter(item => {
            if (!filteredAssets.includes(item) ) {
                return item;
            }
        })
        setUnstakedAssets(unstakedAssets);
        popUp(false);
        setLoading(false);
    }

    async function stakeAssets(assetIDs) {
        await loginHandler.sendTransaction([{
            account: "dovutilstake",
            name: 'regbd',
            authorization: [{
                actor: AccountStore.accountAddress,
                permission: AccountStore.getUserData().requestPermission,
            }],
            data: {
                player: AccountStore.accountAddress,
                asset_ids: assetIDs
            },
        }], AccountStore.getUserData()[0], "Assets staked.")
        setTimeout(() => {
            loginHandler.getUserBalancetkn();
            getAssets().then(() => {
                setChosenAssets("staked")
            }); 
        }, 1000)
    }

   async function unStakeAssets(assetIDs) {
        await loginHandler.sendTransaction([{
            account: "dovutilstake",
            name: 'deregbd',
            authorization: [{
                actor: AccountStore.accountAddress,
                permission: AccountStore.getUserData().requestPermission,
            }],
            data: {
                player: AccountStore.accountAddress,
                asset_ids: assetIDs
            },
        }], AccountStore.getUserData()[0], "Assets unstaked.")
        setTimeout(() => {
            getAssets();
        }, 1000)
    }

    async function switctounstk(varz)
    {
        popUp(true);
        setcType(varz); 
        console.log(ctype);
    }

    useEffect(() => {
        if (AccountStore.accountAddress) {
            getAssets();
        }
    }, [AccountStore.accountAddress])

    
    function renderz() {
        const indexOfLastAsset = currentPage * assetsPerPage;
        const indexOfFirstAsset = indexOfLastAsset - assetsPerPage;
        const vard = unstakedAssets.filter(item => {
            if (item.type==ctype) {
                return item;
            }
        })
        const currentAssets=vard.slice(indexOfFirstAsset, indexOfLastAsset);
       return (
           <div id="divCards" className="select_card_popup">
                               <button className="staking-assets-filters-button staking-assets-filters-button-stake"
                        disabled={loading} onClick={() => {
                            popUp(false)
                        }}>X</button>
                        <div className="select_card_content">
               {currentAssets.map((asset, index) => {
                           return (<div className="sCardsB" key={`unstaked-asset-${index}`}>
                               <div className="staking-asset-name-wrapper">
                                   <span className="staking-asset-name">{asset.name}</span><br/>
                                   <span className="staking-asset-rate">{asset.asset_id}</span>
                                   {asset.token_in.map((cost, index) => {
                                            return (
                                                <div className="dovxText" key={`cons-${index}`}>
                                                    <h4>Stake Fees:</h4>
                                                    <h1>{cost} </h1>
                                                </div>
                                            )
                                        })}
                               </div>
                               <img src={`https://dov-gw.mypinata.cloud/ipfs/${asset.img}`} alt=""
                                   className="staking-asset-img" onClick={() => stakeAssets([asset.asset_id])} />
                           </div>)
                       })}
               </div>
                          <Pagination contentPerPage={assetsPerPage} totalContent={currentAssets.length}
                          paginate={(pageNum) => setCurrentPage(pageNum)} currentPage={currentPage} />
           </div>
       );
   }
       
      function renderAssets()
      {
        const indexOfLastAsset = currentPage * assetsPerPage;
        const indexOfFirstAsset = indexOfLastAsset - assetsPerPage;

        if (chosenAssets === "staked") {
            const currentAssets = stakedAssets.slice(indexOfFirstAsset, indexOfLastAsset);

            return (
                stakedAssets.length !== 0?
                            <>
                                {currentAssets.map((asset, index) => {
                                    var firstCard;
                                    console.log(currentAssets.length);
                                    if ((index == 0) && (currentAssets.length == 3)) { firstCard = 'cards_buildings firstCard'; } else { firstCard = 'cards_buildings'; }
                                    return (
                                    asset.type!="slot"?
                                    <div className="cards_buildings startCounter" key={`staked-asset-${index}`}>
                                        <img src={`https://dov-gw.mypinata.cloud/ipfs/${asset.img}`} alt="" className="staking-asset-img"/>
                                        <div className="tools-asset-name">
                                            <h2>{asset.name}</h2>
                                        </div>
                                        <div className="tools-asset-rate">
                                            <h3>{asset.asset_id}</h3>
                                        </div>
                                        <button className="disassemblyBtn" disabled={loading} onClick={() => unStakeAssets([asset.asset_id])}></button>
                                    </div>:
                                    <div className={firstCard} key={`slots-asset-${index}`} >
                                        <div className='addimg' onClick={()=> switctounstk(asset.varz)}></div>
                                    </div>   )
                                })} 
                                
                                                          
                                                </>
                        : <h4 className="staking-no-assets-title">No assets to show</h4>
            )
        }
      }
      
    return (
        AccountStore.accountAddress ?
        <div className="parent">
            <Notification/>
            <div id="buildings">
                <div className="staking-assets-container">
                    {loading ? <Preloader/> : renderAssets()}
                </div>
            </div>
            {popped?renderz():<></>}

        </div> :
            <div className="wallet-warning">
                <h2 className="warning-message">Please connect your wallet first.</h2>
            </div>
    )
}

export default observer(ToolsPage);
