import "./index.sass";
import "../../main.css";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import eosjsName from "eosjs-account-name";
import Pagination from "../../components/pagination";
import Notification from "../../components/notification";
import { NotificationManager } from "react-notifications";
import Preloader from "../../components/preloader";
import { Int64 } from "anchor-link";
import LoginHandler from "../../functions/login";
import { useTimer } from "react-timer-hook";
import MyTimer from "../../components/timer";

function DivisionsPage() {
  const { AccountStore } = StoreContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const assetsPerPage = 12; //how many assets are shown on screen
  const loginHandler = new LoginHandler();
  const [popped, popUp] = useState(false);
  const [zoneType, SetzoneType] = useState("All");
  const [fusionType, SetfusionType] = useState("All");
  const [rarityType, SetrarityType] = useState("All");
  const [unitType, SetunitType] = useState("");
  const [Power, setTPower] = useState([0, 0, 0]);
  const [Bonus, setTBonus] = useState([0, 0, 0]);
  const [selectedDivision, setSelectedDivision] = useState(0);
  const [selectedZone, setSelectedZone] = useState(0);
  const [slotIndex, setIndex] = useState(0);
  const [divItems, SetdivItems] = useState([
    [
      {
        slot: "officer",
      },
      {
        slot: "support",
      },
      {
        slot: "tactic",
      },
      {
        slot: "godmother",
      },
      {
        slot: "combat%4",
      },
      {
        slot: "combat%5",
      },
      {
        slot: "combat%6",
      },
      {
        slot: "combat%7",
      },
      {
        slot: "combat%8",
      },
      {
        slot: "combat%9",
      },
      {
        slot: "combat%10",
      },
      {
        slot: "combat%11",
      },
      {
        slot: "combat%12",
      },
      {
        slot: "combat%13",
      },
    ],
  ]);

  const wax = loginHandler.wax;

  const Dropdown = ({ label, value, options, onChange }) => {
    return (
      <label className="s">
        {label}
        <select value={value} onChange={onChange} className="selected">
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    );
  };

  function selectDiv(value)
  {
    AccountStore.setSelectedDivision(value);
    if(AccountStore.divisions[AccountStore.selectedDivision])
    if(AccountStore.divisions[AccountStore.selectedDivision].zoneID!== selectedZone.id)
    handlezoneType(AccountStore.divisions[AccountStore.selectedDivision].zoneID)
  }

  function get_rtime(endtime) {
    var current = new Date().getTime() / 1000;
    var tr = endtime - current;
    if (tr < 0) tr = 0;
    var time = new Date().getTime() + tr * 1000;
    return time;
  }


  function ifSave() {
    if(AccountStore.divisions[selectedDivision] === undefined)
    return true;
    else
    return false;
  }

  useEffect(() => {
    if (AccountStore.accountAddress) {
      loginHandler.getUserBalancetkn();
      loginHandler.getUserBalancewax();
      loginHandler.getAssets();
    }
  }, [AccountStore.accountAddress]);

  async function setSlotType( asset) {
    loginHandler.handleassetChange(slotIndex, asset,false);
  }

  function getSlot(asset) {
    var gg = AccountStore.divItems;
    for (let j = 0; j < gg.length; j++) {
      for (let i = 0; i < gg[j].length; i++) {
        if (gg[j][i].asset === asset) {
          return false;
        }
      }
    }
    return true;
  }

  function removeAsset(index) {
    loginHandler.handleassetChange(index, {},true);
  }

  const handlerarityType = (event) => {
    SetrarityType(event.target.value);
  };

  const handlefusionType = (event) => {
    SetfusionType(event.target.value);
  };

  const handlezoneType = (event) => {
    SetzoneType(event);
    var ID = parseInt(event);
    for (let i = 0; i < AccountStore.Zconfig.length; i++) {
      if (AccountStore.Zconfig[i].id === ID) {
        setSelectedZone(AccountStore.Zconfig[i]);
        loginHandler.handleZoneChange(AccountStore.Zconfig[i]);
        break;
      }
    }
  };

  async function choose() {
    var units = [];
    var error = false;
    var edit= AccountStore.divisions[AccountStore.selectedDivision]?true:false;
    for (
      let j = 0;
      j < AccountStore.divItems[AccountStore.selectedDivision].length;
      j++
    ) {
      if (AccountStore.divItems[AccountStore.selectedDivision][j].asset) {
        units.push({
          key: AccountStore.divItems[AccountStore.selectedDivision][j].slot,
          value:
            AccountStore.divItems[AccountStore.selectedDivision][j].asset
              .asset_id,
        });
      }
      if (
        AccountStore.divItems[AccountStore.selectedDivision][j].slot ===
        "officer"
      ) {
        if (!AccountStore.divItems[AccountStore.selectedDivision][j].asset) {
          error = true;
          NotificationManager.error("No Officer Selected", "Warning");
        }
      }
    }
    if (selectedZone.id === 0 || units.length <= 0 ||!selectedZone) {
      error=true;
      NotificationManager.error("No Zone Selected", "Warning");
    }
    var returnData = edit?{
      divID: edit?AccountStore.divisions[AccountStore.selectedDivision].divID:0,
      player: AccountStore.accountAddress,
      setup: units,
      zoneID: selectedZone.id,
    }:
    {
      zoneID: selectedZone.id,
      setup : units,
      player: AccountStore.accountAddress,
    };
    var name= edit?"editdivision":"adddivision";
    if (!error)
      await loginHandler.sendTransaction(
        [
          {
            account: "dovpvecontrl",
            name: name,
            authorization: [
              {
                actor: AccountStore.accountAddress,
                permission: AccountStore.getUserData().requestPermission,
              },
            ],
            data: returnData,
          },
        ],
        AccountStore.getUserData()[0],
        "Transaction successfull"
      );
    setTimeout(() => {
      loginHandler.getAssets();
    }, 1000);
  }


  function getRewardValue() {
    let vv= AccountStore.divisions[AccountStore.selectedDivision]?AccountStore.divisions[AccountStore.selectedDivision]:[];
    if (!vv.zone) return 0;
    for (let i = 0; i < vv.zone.resolutions.length; i++) {
      if (vv.zone.resolutions[i].key === vv.win) {
        return (vv.zone.resolutions[i].value/10000) *(1.00+ parseFloat(vv.bonusd/1000000));
      }
    }
    return 0;
  }

  const currentAssets = AccountStore.allAssets.filter((item) => {
    if (
      item.rarity === (rarityType !== "All" ? rarityType : item.rarity) &&
      item.type === unitType
    )
      return item;
  });

  const currentAssetz = currentAssets.slice();

  function get_DivItem(index) {
    if(selectedZone===0 && AccountStore.divisions[AccountStore.selectedDivision])
    setSelectedZone(AccountStore.divisions[AccountStore.selectedDivision].zone)
    var selected_slot = "";
    selected_slot = AccountStore.divItems[AccountStore.selectedDivision][index];
    var type_slot = selected_slot ? selected_slot.slot.split("%")[0] : "combat";
    return (
      <div
        className={
          selected_slot.slot.includes("combat") ? "cards_front" : "cards_hq"
        }
      >
              {selected_slot.asset?
        <>
                      {/* <p className="">{selected_slot.asset.power!==0?
                      (parseFloat(selected_slot.asset.power)/10000*
                      (parseFloat((loginHandler.getBonus(selected_slot.asset,
                      loginHandler.capitalizeFirstLetter(selectedZone.terrain))[2]||0)))/10000).toFixed(4):
                      loginHandler.getBonus(selected_slot.asset,loginHandler.capitalizeFirstLetter(selectedZone.terrain))?
                      loginHandler.getBonus(selected_slot.asset,loginHandler.capitalizeFirstLetter(selectedZone.terrain))[0]!==0?
                      loginHandler.getBonus(selected_slot.asset,loginHandler.capitalizeFirstLetter(selectedZone.terrain))[0]/10000 + " bonusd"
                      :
                      loginHandler.getBonus(selected_slot.asset,loginHandler.capitalizeFirstLetter(selectedZone.terrain))[1]/10000 + " bonusw"
                      :0}
                      </p> */}
        <img
          src={
            selected_slot.asset
              ? `https://dov-gw.mypinata.cloud/ipfs/${selected_slot.asset.img}`
              : selected_slot
              ? require("../../assets/dov/slot-non-dispo_x2.png")
              : ""
          }
          alt=""
          onClick={() => {
            SetunitType(type_slot);
            setIndex(index);
          }}
        />
        <button
          alt=""
          className="removeBtn"
          onClick={() => removeAsset(index)}
        />
        </>
        :<>
        <img
          src={require("../../assets/dov/slot-non-dispo_x2.png")}
          alt=""
          onClick={() => {
            SetunitType(type_slot);
            setIndex(index);
          }}
        />
        </>}
      </div>
    );
  }

  return (
    <div className="parent">
      <Notification />

      <div className="leftZone">
        <div className="titleSelectorL">
          Select an open zone<span></span>
        </div>

        {[1, 5, 9, 13, 17].map((number, index) => {
          return (
            <div className="openZone">
              <div className="imgZone">
                <img
                  src={require(`../../assets/dov/divisions/Zone-${number}.jpg`)}
                  alt="ics"
                />
              </div>
              <div className="btnZone">
                {AccountStore.Zconfig.slice(number - 1, number + 3).map(
                  (asset, index) => {
                    return (
                      <button
                        key={index}
                        className={
                            (AccountStore.divisions[AccountStore.selectedDivision]?AccountStore.divisions[AccountStore.selectedDivision].zoneID===asset.id:false) 
                            ? "whiteColor"
                            :
                            (asset===selectedZone)
                            ? "redBackColor"
                            : (!asset.reachable || !asset.open)
                            ? ""
                            :"selected"
                        }
                       onClick={() =>{asset.reachable && asset.open? handlezoneType(asset.id):<></>}}
                      >
                        {asset.name}
                      </button>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
        <div className="arrowHR"></div>
        <div className="costZone">
          {selectedZone ? (
            <span>
              {selectedZone.costs ? (
                <>
                  <span>{((selectedZone.costs.filter(a=>a.key==="DOVH").length>0?selectedZone.costs.filter(a=>a.key==="DOVH")[0].value:0) / 10000).toFixed(4)} </span>
                  <span>{((selectedZone.costs.filter(a=>a.key==="DOVX").length>0?selectedZone.costs.filter(a=>a.key==="DOVX")[0].value:0) / 10000).toFixed(4)} </span>
                </>
              ) : (
                <></>
              )}
            </span>
          ) : (
            <></>
          )}
        </div>
        <div className="winsZone">
          <span>{selectedZone.nbwins}</span>
        </div>
        <div className="earningsZone">
          <span>{parseFloat(getRewardValue()).toFixed(3)}</span>
        </div>

      </div>
      <div className="rightZone">
        <div className="">
          <div className="divSelector">
            <div className="titleSelector">
              Select a Division then develop its composition<span></span>
            </div>
            <div className="divBtn">
              <button
                className={
                  AccountStore.selectedDivision === 0 ? "blackColor" : ""
                }
                onClick={() => selectDiv(0)}
              >
                DIVISION 1
              </button>
              <button
                className={
                  AccountStore.selectedDivision === 1 ? "blackColor" : ""
                }
                onClick={() => selectDiv(1)}
              >
                DIVISION 2
              </button>
              <button
                className={
                  AccountStore.selectedDivision === 2 ? "blackColor" : ""
                }
                onClick={() => selectDiv(2)}
              >
                DIVISION 3
              </button>
            </div>
            <div className="divSlots">
              <div id="board_division">
                <div id="cards_hq_title">
                  <p id="one" className="rotate">
                    HEADQUARTER
                  </p>
                </div>
                {AccountStore.divItems[AccountStore.selectedDivision]
                  .filter((a) => !a.slot.includes("combat"))
                  .map((asset, index) => {
                    return get_DivItem(index);
                  })}
              </div>
              <div id="board_division2">
                <div id="cards_front_title">
                  <p id="second" className="rotate">
                    FRONT LIGNE 1
                  </p>
                  <p id="third" className="rotate">
                    FRONT LIGNE 2
                  </p>
                </div>
                {AccountStore.divItems[AccountStore.selectedDivision]
                  .filter((a) => a.slot.includes("combat"))
                  .map((asset, index) => {
                    return get_DivItem(4 + index);
                  })}

          </div>
              <div className="btnReset">
                            <button className="mgt15"
                  onClick={() => {
                    loginHandler.deleteDivision();
                  }}
                >
                  <span>Reset division</span>
                </button>
          </div>
          </div>
          </div>
          <div className="divCards">
            <div className="divZone mgt15">
              <div className="divLeftDP">
                <span>Total Division Power</span>
              </div>
              <div className="divRightDP orangeColor">
                <span>
                  {AccountStore.divisions[AccountStore.selectedDivision]?
                 (parseFloat(AccountStore.divisions[AccountStore.selectedDivision].Total_Power)).toFixed(4):0}
                </span>
              </div>
            </div>
            <div className="divZone">
              <div className="divAuth">
                <button
                  className={
                    AccountStore.divisions[AccountStore.selectedDivision] &&
                    AccountStore.divisions[AccountStore.selectedDivision]
                      .win !== "X"
                      ? "greenBack"
                      : ""
                  }
                >
                  Authorized to fight
                </button>
              </div>
              <div className="divAuth ">
                <button
                  className={
                    AccountStore.divisions[AccountStore.selectedDivision] &&
                    AccountStore.divisions[AccountStore.selectedDivision]
                      .win === "X"
                      ? "orangeColor"
                      : ""
                  }
                >
                  Unauthorized to fight
                </button>
              </div>
            </div>
            <div className="divZone">
              <div className="divVDD">
                <button
                  className={
                    AccountStore.divisions[AccountStore.selectedDivision] &&
                    AccountStore.divisions[AccountStore.selectedDivision]
                      .win === "win"
                      ? "orangeColor"
                      : ""
                  }
                >
                  Victory
                </button>
              </div>
              <div className="divVDD">
                <button
                  className={
                    AccountStore.divisions[AccountStore.selectedDivision] &&
                    AccountStore.divisions[AccountStore.selectedDivision]
                      .win === "draw"
                      ? "orangeColor"
                      : ""
                  }
                >
                  Draw
                </button>
              </div>
              <div className="divVDD">
                <button
                  className={
                    AccountStore.divisions[AccountStore.selectedDivision] &&
                    AccountStore.divisions[AccountStore.selectedDivision]
                      .win === "loss"
                      ? "orangeColor"
                      : ""
                  }
                >
                  Defeat
                </button>
              </div>
            </div>
            <div id="selectCard" className="titleSelector">
              Select a card<span></span>
              <Dropdown
                label=""
                options={[
                  { label: "All", value: "All" },
                  { label: "Common", value: "Common" },
                  { label: "Uncommon", value: "Uncommon" },
                  { label: "Rare", value: "Rare" },
                  { label: "Epic", value: "Epic" },
                  { label: "Legendary", value: "Legendary" },
                  { label: "Supreme", value: "Supreme" },
                ]}
                value={rarityType}
                onChange={handlerarityType}
              />
            </div>
            <div className="blocCards">
              <div id="select_card">
                {currentAssetz.map((asset, index) => {
                  return getSlot(asset) ? (
                    <div className="sCards" key={`unstaked-asset-${index}`}>
                      <p className="">{asset.name}</p>
                      <sup />
                      <p className="">{asset.asset_id}</p>
                      <img
                        src={`https://dov-gw.mypinata.cloud/ipfs/${asset.img}`}
                        alt=""
                        className=""
                        onClick={() => setSlotType(asset)}
                      />
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
            </div>
            <div className="btnScroll">
              <button>
                <img
                  src="../../assets/dov/divisions/DIV_CREA-division-select-a-card-bouton-défilement.jpg"
                  alt=""
                />
              </button>
            </div>
            {(!AccountStore.divisions[AccountStore.selectedDivision])? (
              <div className="btnSave">
                <button className="mgr15"
                  onClick={() => {
                    choose();
                  }}
                >
                  <span>Save division</span>
                </button>
              </div>
            ) : (
              <div className="btnSave">
                               <button
                  onClick={() => {
                    choose();
                  }}
                >
                  <span>Edit division</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(DivisionsPage);
