import "./index.sass";
import "../../main.css";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import * as waxjs from "@waxio/waxjs/dist";
import { useEffect, useState } from "react";
import eosjsName from "eosjs-account-name";
import Pagination from "../../components/pagination";
import Notification from "../../components/notification";
import { NotificationManager } from "react-notifications";
import Preloader from "../../components/preloader";
import { Int64 } from "anchor-link";
import MyTimer from "../../components/timer";
import LoginHandler from "../../functions/login";
import { BrowserRouter as Router, Link } from "react-router-dom";

function HOF() {
  const { AccountStore } = StoreContext();
  const [loading, setLoading] = useState(false);
  const [filteredWins, setFilteredWins] = useState([]);
  const [divisions, setDivisions] = useState([
    {
      id: 1,
      account: "slicksheep12",
      divID: 1,
      score: 0,
    }
  ]);
  const loginHandler = new LoginHandler();

  useEffect(() => {
    if (AccountStore.accountAddress) {
      loginHandler.getZonesConfig();
      loginHandler.getWinsScope();
      }
  }, [AccountStore.accountAddress]);

function filterAccountZoneWins(SelectedTerrain)
{
var zoneWins = AccountStore.winsScope;
var Zconfig=AccountStore.Zconfig;

for(var i = 0; i < zoneWins.length; i++)
{
const UserName= zoneWins[i].name;
for(var j = 0; j < zoneWins[i].balances.length; j++)
{
const zoneID=zoneWins[i].balances[j].zone;
var terrain="unknown";
for(var k = 0; k < Zconfig.length; k++)
{
if(zoneID===Zconfig[k].id && Zconfig[k].name===SelectedTerrain)
{
 terrain=Zconfig[k].name;
}
}
if(terrain===SelectedTerrain)
{
var exists=false;
for(var l = 0; l < filteredWins.length; l++)
{
if(filteredWins[l].name===terrain)
{
    if(filteredWins[l].accounts.filter(e => e.name === UserName).length === 0)
    {
    filteredWins[l].accounts.push(
      {
        name: UserName,
        wins: zoneWins[i].balances[j].wins
      });
      filteredWins[l].accounts.sort((a, b) => b.wins-a.wins);
  exists=true;
  break;
}
}
}
if(!exists)
filteredWins.push(
  {
    name: terrain,
    accounts: [
      {
        name: UserName,
        wins: zoneWins[i].balances[j].wins
      }
    ]
  });
}
}
}
console.log(filteredWins);
for(var x=0;x<filteredWins.length;x++)
{
if(filteredWins[x].name===SelectedTerrain)
{
  var accounts=filteredWins[x].accounts;
  var copyAccounts= accounts.slice(0,5);
  console.log(copyAccounts);
return {accounts:copyAccounts};
}
}

return {accounts:[]};
}

function filterDivisions()
{
var allDivisions=AccountStore.allDivisions.slice();
allDivisions.sort((a, b) => b.power-a.power);
return allDivisions.slice(0,20);
}

  return (
        <div className="parent">
        <div class="left50">
                        <div id="topOfficer">
                           <div class="topTitle">
                              <span>TOP 5</span>
                           </div>
                           <div class="topDesc">
                              <h2>Officers</h2>
                              <span>(ranked by number of victories on objectives)</span>
                           </div>
                        </div>
                        <div class="clearfix"></div>
                        <div id="topArmed">
                           <div class="arrow"></div>
                           <div class="topTitle">
                              <span>TOP 20</span>
                           </div>
                           <div class="topDesc">
                              <h2>Armed forces</h2>
                              <span>(Ranked by the strenght of the military divisions)</span>
                           </div>
                        </div>
                        <div class="clearfix"></div>
                        <div id="ArmedTable" class="tableHoF">
                           <table>
                               <tbody>
                           {filterDivisions().map((asset, index) => {
                            return (
                                      <tr>
                                        <td><span>{index+1}</span></td>
                                        <td><span>{asset.owner}</span></td>
                                        <td><span>ID: {asset.divID}</span></td>
                                        <td>{parseFloat(asset.power/10000).toFixed(1)}</td>
                                      </tr>
                                      )})}
                               </tbody>
                           </table>
                        </div>
                     </div>
                     <div class="right50">
                        <div id="bunkerHoF" class="zoneHoF">
                           <div class="leftZoneHoF">
                              <img src={require("../../assets/dov/hof/bunker.png")} alt="" />
                           </div>
                           <div class="rightZoneHoF tableHoF">
                              <table>
                                  <tbody>
                              {filterAccountZoneWins("bunker").accounts.map((asset, index) => {
                            return (
                                      <tr>
                                        <td><span>{index+1}</span></td>
                                        <td><span>{asset.name}</span></td>
                                        <td>{asset.wins} Victories</td>
                                      </tr>
                                      )})}
                                  </tbody>
                              </table>
                           </div>
                        </div>     
                        <div class="clearfix" style={{"padding":"5px"}} ></div>
                        <div id="campHoF" class="zoneHoF">
                           <div class="leftZoneHoF">
                              <img src={require("../../assets/dov/hof/camp.png")} alt="" />
                           </div>
                           <div class="rightZoneHoF tableHoF">
                              <table>
                                  <tbody>
                              {filterAccountZoneWins("camp").accounts.map((asset, index) => {
                            return (
                                      <tr>
                                      <td><span>{index+1}</span></td>
                                        <td><span>{asset.name}</span></td>
                                        <td>{asset.wins} Victories</td>
                                      </tr>
                                      )})}
                                  </tbody>
                              </table>
                           </div>
                        </div>     
                        <div class="clearfix" style={{"padding":"5px"}} ></div>
                        <div id="radarHoF" class="zoneHoF">
                           <div class="leftZoneHoF">
                              <img src={require("../../assets/dov/hof/radar.png")} alt="" />
                           </div>
                           <div class="rightZoneHoF tableHoF">
                              <table>
                                  <tbody>
                              {filterAccountZoneWins("radar").accounts.map((asset, index) => {
                            return (
                                      <tr>
                                      <td><span>{index+1}</span></td>
                                        <td><span>{asset.name}</span></td>
                                        <td>{asset.wins} Victories</td>
                                      </tr>
                                      )})}
                                  </tbody>
                              </table>
                           </div>
                        </div>     
                        <div class="clearfix" style={{"padding":"5px"}} ></div>
                        <div id="hqHoF" class="zoneHoF">
                           <div class="leftZoneHoF">
                              <img src={require("../../assets/dov/hof/hq.png")} alt="" />
                           </div>
                           <div class="rightZoneHoF tableHoF">
                              <table>
                                  <tbody>
                              {filterAccountZoneWins("hq").accounts.map((asset, index) => {
                            return (
                                      <tr>
                                      <td><span>{index+1}</span></td>
                                        <td><span>{asset.name}</span></td>
                                        <td>{asset.wins} Victories</td>
                                      </tr>
                                      )})}
                                  </tbody>
                              </table>
                           </div>
                        </div>     
                        <div class="clearfix" style={{"padding":"5px"}} ></div>
                        <div id="bridgeHoF" class="zoneHoF">
                           <div class="leftZoneHoF">
                              <img src={require("../../assets/dov/hof/bridge.png")} alt="" />
                           </div>
                           <div class="rightZoneHoF tableHoF">
                              <table>
                                  <tbody>
                              {filterAccountZoneWins("bridge").accounts.map((asset, index) => {
                            return (
                                      <tr>
                                      <td><span>{index+1}</span></td>
                                        <td><span>{asset.name}</span></td>
                                        <td>{asset.wins} Victories</td>
                                      </tr>
                                      )})}
                                  </tbody>
                              </table>
                           </div>
                        </div>     
                      </div>     
        </div>
  );
}

export default observer(HOF);
