import { makeAutoObservable } from "mobx";
import { useEffect, useState } from "react";

class AccountStore {
  accountAddress = null;
  userData = [];
  config = {wfee:0,use_dov:1,max_type:4};
  winsScope=[];
  blcData = {
    dovx: "0.00",
    dovr: "0.00",
    dovs: "0.00",
    dovf: "0.00",
    dovh: "0.00",
    avt: "1",
  };
  wblcData = {
    dovx: "0.00",
    dovr: "0.00",
    dovs: "0.00",
    dovf: "0.00",
    dovh: "0.00",
  };
  unstakedAssets = [];
  stakedAssets = [];
  allAssets = [];
  Zconfig = [];
  Uconfig = [];
  ZoneWins = [];
  allDivisions=[];
  selectedZone = [];
  selectedDivision = 0;
  divisions = [];
  divItems = [
    [
      {
        slot: "officer",
      },
      {
        slot: "support",
      },
      {
        slot: "tactic",
      },
      {
        slot: "godmother",
      },
      {
        slot: "combat%4",
      },
      {
        slot: "combat%5",
      },
      {
        slot: "combat%6",
      },
      {
        slot: "combat%7",
      },
      {
        slot: "combat%8",
      },
      {
        slot: "combat%9",
      },
      {
        slot: "combat%10",
      },
      {
        slot: "combat%11",
      },
      {
        slot: "combat%12",
      },
      {
        slot: "combat%13",
      },
    ],
    [
      {
        slot: "officer",
      },
      {
        slot: "support",
      },
      {
        slot: "tactic",
      },
      {
        slot: "godmother",
      },
      {
        slot: "combat%4",
      },
      {
        slot: "combat%5",
      },
      {
        slot: "combat%6",
      },
      {
        slot: "combat%7",
      },
      {
        slot: "combat%8",
      },
      {
        slot: "combat%9",
      },
      {
        slot: "combat%10",
      },
      {
        slot: "combat%11",
      },
      {
        slot: "combat%12",
      },
      {
        slot: "combat%13",
      },
    ],
    [
      {
        slot: "officer",
      },
      {
        slot: "support",
      },
      {
        slot: "tactic",
      },
      {
        slot: "godmother",
      },
      {
        slot: "combat%4",
      },
      {
        slot: "combat%5",
      },
      {
        slot: "combat%6",
      },
      {
        slot: "combat%7",
      },
      {
        slot: "combat%8",
      },
      {
        slot: "combat%9",
      },
      {
        slot: "combat%10",
      },
      {
        slot: "combat%11",
      },
      {
        slot: "combat%12",
      },
      {
        slot: "combat%13",
      },
    ],
  ];
  loading = false;

  avatarData = [];
  constructor() {
    makeAutoObservable(this);
  }

  changeAccountAddress(addr) {
    this.accountAddress = addr;
  }

  modifyDivItems(value) {
    console.log(value);
    this.divItems = value;
    console.log(this.divItems);
  }
  setConfig(value)
  {
    this.config = value;
  }
  modifyDivisions(div, index, change) {
    this.divisions[div].units[index].value = change;
  }

  changeDivision(change) {
    console.log(change);
    this.divisions = change;
  }

  removeassetDiv(div, asset) {
    this.divisions[div].units = this.divisions[div].units.units.filter(
      function (item) {
        return item.value !== asset.asset_id;
      }
    );
  }
  setWinsScope(val)
  {
    this.winsScope = val;
  }

  setAllDivisions(val)
  {
    this.allDivisions = val;
  }

  setUserData(val) {
    this.userData = val;
  }

  setDivisions(val) {
    this.divisions = val;
  }

  getDivisions() {
    return this.divisions;
  }

  setBlcData(val) {
    this.blcData = val;
  }
  setSelectedDivision(val) {
    this.selectedDivision = val;
  }
  setWBlcData(val) {
    this.wblcData = val;
  }

  setLoading(data) {
    this.loading = data;
  }

  SetUConfig(data) {
    this.Uconfig = data;
  }

  SetZConfig(data) {
    this.Zconfig = data;
  }

  SetselectedZone(data) {
    this.selectedZone = data;
  }

  setStakedAssets(data) {
    this.stakedAssets = data;
  }
  setAllAssets(data) {
    this.allAssets = data;
  }

  setUnstakedAssets(data) {
    this.unstakedAssets = data;
  }

  setAvatarConfig(value) {
    this.avatarData = value;
  }

  SetZoneWins(value) {
    this.ZoneWins = value;
  }

  GetZoneWins(zone_id) {
    var balances = this.ZoneWins;
    for (let i = 0; i < balances.length; i++) {
      if (balances[i].zone === zone_id) {
        return balances[i].wins;
      }
    }
    return 0;
  }

  getAvatarIndex() {
    console.log(this.blcData.avt);
    var found = false;
    for (let i = 0; i < this.avatarData.length; i++) {
      if (this.avatarData[i].id === this.blcData.avt) {
        found = true;
        return this.avatarData[i];
      }
    }
    if (!found) {
      var avatar = {
        id: 1,
        ipfs: "QmXPqM2gGnfJQ1FAB7VnD9ygBcSLTnV3aevQ5dH3h2qc71",
        cost: "Select Avatar",
      };
      return avatar;
    }
  }
  getBlcData() {
    return this.blcData;
  }
  getWBlcData() {
    return this.wblcData;
  }
  getUserData() {
    return this.userData;
  }
  getConfig() {
    return this.config;
  }
  getAccountAddress() {
    return this.accountAddress;
  }
}

export default new AccountStore();
